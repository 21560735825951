import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/layout"
import SEO from "../components/seo"

import Card from "../components/card/card"
import SectionHeader from "../components/section-header/section-header"
import FaqQuestion from "../components/faq-question/faq-question"
import ContactForm from "../components/contact-form/contact-form"

import "./index.scss"

import logo from "../images/uxanimation-logo.svg"
import phoneDesktop from "../images/phoneDesktop.svg"
import phoneMobile from "../images/phoneMobile.svg"

import QuoteOpen from "../images/quote-open.svg"
import QuoteClose from "../images/quote-close.svg"

import LogoIntroData from "../animations/logo-intro.json"
import MicrointeractionData from "../animations/microinteraction.json"
import OnboardingData from "../animations/onboarding.json"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />


    <section className="header">

      <div className="container">

        <div className="content">
          <img className="logo-desktop" src={logo} alt="UX animation"/>
          <h1>
            Enhance your <strong>App<br/> User Experience</strong> with<br/>
            Lottie <strong>Animation</strong>
          </h1>
          <p>
            Custom creative and functional animations <br/>
            that will take your app to the next level.
          </p>

          <AnchorLink className="cta" to="/#form" title="Get a quote">
            Enhance my App
          </AnchorLink>
        </div>

        <img className="illustra-desktop" src={phoneDesktop} alt="phone illustration"/>
        <img className="illustra-mobile" src={phoneMobile} alt="phone illustration"/>

        <img className="logo-mobile" src={logo} alt="UX animation"/>
        
      </div>
    </section>


    <section className="services">
      <div className="container">

        <SectionHeader
          title="Popular uses of animations"
          description="These are some of the most popular uses of animation in apps, but the possibilities are far beyond that. How can we help you?"
         />

        <div className="cards-container">
        <Card title="Logo intro" animationData={LogoIntroData} 
          description="Show your attention to details from the moment users open your app and see a delightful animated logo." 
        />
        <Card title="Microinteractions"  animationData={MicrointeractionData}
          description="Animations that keep the users informed about app processes in a way that reduces their cognitive load."
        />
        <Card title="Onboarding"  animationData={OnboardingData}
          description="Entertain users at the same time you show them how to use your app through an animated explanation."
        />
        </div>

      </div>
    </section>


    <section className="quote">

      <div className="quote-container">
        <img src={QuoteOpen} alt="Quotation mark" className="mark1"/>
        <div className="quote-content">
          <h3>
            Any animation applied in the user interface should have a clear purpose enhancing general user experience.
          </h3>
          <p>
            Author of the quotation
          </p>
        </div>
        <img src={QuoteClose} alt="Quotation mark"  className="mark2" />
      </div>

    </section>

    
    <section className="contact">
      
      <div className="container">
        
        <SectionHeader
          title="Get in touch"
          description="Describe the kind of animation you need to take your app to the next level. Give us details and send links to reference animations you like."
        />


        <div className="content">

          <div className="contact-info">
            <p>
              Email: contact@uxanimation.co <br/>
              
              Av. Paulista, 171, Bela Vista, São Paulo - Brazil
            </p>
          </div>

          <ContactForm />

        </div>


      </div>

    
    </section>


    <section className="faq">

      <div className="container">

        <SectionHeader
          title="Frequently Asked Questions "
          description="Nunc non massa nullam aliquet diam. Quam sem ullamcorper arcu faucibus lorem consectetur nulla condimentum."
        />

        <div className="content">

          <FaqQuestion
            question="Epcot Center Epcot Center Epcot Center Center Epcot Center"
            answer="Epcot is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks and seasonal special events."
          />
          <FaqQuestion
            question="Epcot Center"
            answer="Epcot is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks and seasonal special events."
          />
          <FaqQuestion
            question="Epcot Center"
            answer="Epcot is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks and seasonal special events."
          />
          <FaqQuestion
            question="Epcot Center"
            answer="Epcot is a theme park at Walt Disney World Resort featuring exciting attractions, international pavilions, award-winning fireworks and seasonal special events."
          />

        </div>

      </div>      

    </section>


  </Layout>
)

export default IndexPage
